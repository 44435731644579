import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFormik } from 'formik';
import UnitAccordion from '../components/unitAccordion';
import useAPI from '../../../hooks/useAPI';
import SmallLoader from '../../widgets/SmallLoader'
import { states } from '../../../services/base'
import { useNavigate } from 'react-router';
import { tenantInitialValues, roomInitialValues } from '../../../services/utils';
import { BURL } from '../../../services/base';
import axios from 'axios';
import { string } from 'yup';

const steps = ['Find User', 'Tenant Details'];

export default function TenantHorizontalLinearStepper({ getProperties, open, setOpen }) {

    // Formik form
    const { values, handleChange, resetForm } = useFormik({ initialValues: tenantInitialValues })
    // End of formik

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [properties, setProperties] = React.useState([]);
    const [rooms, setRooms] = React.useState([]);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [daysBetween, setDaysBetween] = React.useState(0);

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const nextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    const handleNext = () => {
        if (activeStep + 1 === 1) {
            findUserWithEmail();
        } else if (activeStep + 1 === 2) {
            addTenants();
        } else {
            nextStep()
        }
    };

    const { POST, GET } = useAPI()
    const [loading, setLoading] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [property, setProperty] = React.useState(null);
    const [units, setUnits] = React.useState([]);
    const [propertyId, setPropertyId] = React.useState(string || null);
    const [unitId, setUnitId] = React.useState(string);
    const [email, setEmail] = React.useState('');
    const [isTenantDetails, setIsTenantDetails] = React.useState(false);
    const [userId, setUserId] = React.useState('');
    const [tenantPayload, setTenantPayload] = React.useState({
        userId: 0,
        propertyId: 0,
        roomId: 0,
        checkIn: '',
        stayDuration: ''
    });
    const [userDetails, setUserDetails] = React.useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        propertyId: 0,
        roomId: 0,
        checkIn: '',
        stayDuration: ''
    });

    const token = localStorage.getItem('token');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const findUserWithEmail = async () => {
        setIsTenantDetails(false);
        setErrorText('');
        try {
            const response = await axios.get(`${BURL}/app/user`, {
                params: {
                    email
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('res', response);

            if (response?.status) {
                tenantPayload.userId = response.data.data.userId;
                response.data.data ? setIsTenantDetails(true) : setIsTenantDetails(false);
                console.log('datasd', response.data.data);

                setUserId(response.data.data.userId);
                setUserDetails({
                    firstname: response.data.data.firstname,
                    lastname: response.data.data.lastname,
                    email: response.data.data.email,
                    phone: response.data.data.phone
                });
                if (response.status) nextStep();
            } else {
                const errorMessage = typeof response.message === 'string'
                    ? response.message
                    : JSON.stringify(response.message);
                setErrorText(errorMessage);
                setIsTenantDetails(false);
            }
        } catch (err) {
            console.log('eeh', err);
            if (err?.response.data.message == 'Account does not exist') {
                setUserDetails({
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: ''
                });
                nextStep();
            } else {
                setErrorText(err?.response.data.message || 'An unexpected error occurred.');
                setIsTenantDetails(false);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value
        });
    };

    const getAllProperties = React.useCallback(async () => {
        setLoading(true);
        setErrorText(null);
        const response = await GET(`admin/properties`);
        setLoading(false);

        if (response?.status) {
            setProperties(response.data);
        } else {
            setErrorText(response.message ?? 'Request Error');
        }
        // eslint-disable-next-line
    }, []);

    const handlePropertyChange = async (e) => {
        setPropertyId(e.target.value);
        getUnitByPropertyId(e.target.value);
    }

    const handleUnitChange = async (e) => {
        setUnitId(e.target.value);
        getRoomByUnit(e.target.value);
    }

    const calculateDays = () => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Calculate the difference in milliseconds
        const diffInMs = end - start;

        // Convert milliseconds to days
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays;
    };

    React.useEffect(() => {
        getAllProperties();
    }, []);

    React.useEffect(() => {
        getAllProperties();
    }, []);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setOpen(false);
        setDone(false);
        window.location.reload();
    };

    // Property
    const propertyList = properties.map(property => {
        return (
            <MenuItem value={property.propertyId}>{property.property.name}</MenuItem>
        )
    })
    // End of property  

    // Unit
    const unitList = units.map(unit => {
        return (
            <MenuItem value={unit.id}>{unit.name}</MenuItem>
        )
    })
    // End of unit  

    // Room
    const roomList = rooms.map(room => {
        return (
            <MenuItem value={room.id}>{room.tag}</MenuItem>
        )
    })
    // End of room  

    // handling multiple units

    React.useEffect(() => {
        if (activeStep === 1 && !units.length) addUnit();
        // eslint-disable-next-line
    }, [activeStep, units.length])

    const addUnit = () => {
        setUnits(e => [...e, {
            id: e.length + 1,
            name: 'New Unit ' + (e.length + 1),
            noOfRooms: 1, saved: false,
            rooms: [{ tag: 'Room 1' }],
        }])
    }

    // when the dialog close
    React.useEffect(() => {
        if (!open && done) {
            setOpen(false);
            setDone(false);
            setActiveStep(0);
        }
        // refetch properties if the method is been passed down to this component
        getProperties && getProperties();
        // eslint-disable-next-line
    }, [open, done])


    // check if setup is completed
    React.useEffect(() => {
        // get unsaved units
        const unsavedUnits = units.filter(each => !each.saved)
        // unit setup step is skipped or (we have units, no unsaved unit and property has been set)
        if ((!unsavedUnits.length && property && units.length) || isStepSkipped(1)) {
            resetForm();
            setDone(true)
            setUnits([]);
            setActiveStep(2);
            setProperty(null);
            setSkipped(new Set())
        }
        // eslint-disable-next-line
    }, [units, property, activeStep]);

    const getUnitByPropertyId = React.useCallback(async (propertyId) => {
        if (propertyId) {
            const token = localStorage.getItem('token');
            setLoading(true);
            setErrorText(null);
            const response = await axios.get(`${BURL}/admin/properties/${propertyId}/units`, {
                params: {
                    withRooms: false,
                    withProperty: false
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // const response = await GET(`admin/properties`)
            setLoading(false);
            if (response?.status) {
                const res = response.data.data
                setUnits(res);
            } else {
                // setErrorText(response.message ?? 'Request Error');
            }
        }
    });

    const getRoomByUnit = React.useCallback(async (id) => {
        if (id) {
            setLoading(true);
            setErrorText(null);
            const response = await axios.get(`${BURL}/admin/properties/units/${id}/rooms`, {
                params: {
                    withProperty: false,
                    withUnit: true,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLoading(false);

            if (response?.status) {
                const res = response.data.data
                setRooms(res);
            } else {
                setErrorText(response.message ?? 'Request Error');
            }
        }
    });

    React.useEffect(() => {
        if (propertyId) {
            getUnitByPropertyId();
        }
        if (unitId) {
            getRoomByUnit();
        }
    }, []);


    const addTenants = async () => {
        setLoading(true);
        setErrorText(null);
        console.log('propertyId', propertyId, calculateDays(), startDate);

        try {
            if (isTenantDetails) {
                tenantPayload.checkIn = startDate;
                tenantPayload.propertyId = propertyId;
                tenantPayload.roomId = userDetails.roomId;
                // tenantPayload.userId = userId;
                const calcDay = calculateDays();
                tenantPayload.stayDuration = calcDay;
                console.log('tenantPayload', tenantPayload);
            } else {
                userDetails.checkIn = startDate;
                userDetails.propertyId = propertyId;
                const calcDay = calculateDays();
                userDetails.stayDuration = calcDay;
                console.log('userDetails', userDetails);
            }
            if (!isTenantDetails) tenantPayload.roomId = userDetails.roomId;

            const response = isTenantDetails ? await POST(`admin/tenant-room`, tenantPayload) : await POST(`admin/tenant-room`, userDetails);
            if (!response.error) {
                nextStep();
                userDetails = '';
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            setErrorText(err?.message || 'An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    }

    const loader = <SmallLoader />
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        <div className="max-w-md p-5 text-center">
                            Tenant setup has been completed. Click proceed to view tenants or click out to close this dialog.
                        </div>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Proceed</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div sx={{ mt: 2, mb: 1 }}>
                        {
                            activeStep === 0 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'>Find User</h1>
                                    <p className="text-[0.8em] mt-2">Enter the tenant email address, to fetch the tenant details.</p>
                                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                </div>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className='w-full'>
                                        <TextField fullWidth name="email" label="Email" type='email' value={email} onChange={handleEmailChange} variant="outlined" />
                                    </div>
                                </Box>
                            </div>
                        }
                        {
                            activeStep === 1 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'> Tenant Details</h1>
                                    {isTenantDetails ? <p className="text-[14px] mt-2">Below is the details of the user. <br /> NOTE: This is displayed because the searched email address is registered. </p> :
                                        <p className="text-[0.8em] mt-2">User does not exist. <br /> Fill in the neccessary information to create tenant </p>}
                                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                </div>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className="w-100 gap-4 grid">
                                        <div className="w-100 gap-3 grid grid-cols-[58%,40%]">
                                            <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                                <label htmlFor="" className=''>First Name</label>
                                                <input type="text" className='text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#0000008f] capitalize' name='firstname' value={userDetails.firstname} onChange={handleInputChange} placeholder="John" />
                                            </div>
                                            <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                                <label htmlFor="" className=''>Last Name</label>
                                                <input type="text" className='text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#000000ba] capitalize' name='lastname' value={userDetails.lastname} onChange={handleInputChange} placeholder="Doe" />
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                            <label htmlFor="" className=''>Email</label>
                                            <input type="email" className='text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#000000ba]' name='email' value={userDetails.email} onChange={handleInputChange} placeholder="johndoe@gmail.com" />
                                        </div>
                                        <div className="w-100 gap-3 grid grid-cols-[58%,40%] mt-2">
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth >
                                                    <InputLabel id="demo-simple-select-label">Property</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='propertyId'
                                                        value={propertyId}
                                                        onChange={handlePropertyChange}
                                                        MenuProps={{
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                        }}
                                                    >
                                                        {propertyList}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth >
                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='unitId'
                                                        value={unitId}
                                                        onChange={handleUnitChange}
                                                        MenuProps={{
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                        }}
                                                    >
                                                        {unitList}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth >
                                                <InputLabel id="demo-simple-select-label">Room</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='roomId'
                                                    value={userDetails.roomId}
                                                    onChange={handleInputChange}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }}
                                                >
                                                    {roomList}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <div className="w-100 gap-3 grid grid-cols-[47%,47%]">
                                            <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                                <label htmlFor="" className=''>Start Date</label>
                                                <input type="date" className='border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:font-[500]' name="startDate" value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)} />
                                            </div>
                                            <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                                <label htmlFor="" className=''>End Date</label>
                                                <input type="date" className='border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:font-[500]' name="endDate" value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        }
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )} */}

                        <Button onClick={handleNext} disabled={loading} className='flex gap-1 items-center'>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}

                            {loading && loader}
                        </Button>
                    </Box>
                </React.Fragment>
            )
            }
        </Box >
    );
}

export function RoomHorizontalLinearStepper({ getRoomsByUnit, open, setOpen, currentPropertyId, currentUnitId }) {
    const steps = ['Room Details'];
    // Formik form
    const { values, handleChange, resetForm } = useFormik({ initialValues: roomInitialValues })
    // End of formik

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [properties, setProperties] = React.useState([]);
    const [roomUnit, setRoomUnit] = React.useState(null);

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const nextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    const handleNext = () => {
        if (activeStep + 1 === 1) {
            addRooms();
        } else {
            nextStep()
        }
    };

    const { POST, GET } = useAPI()
    const [loading, setLoading] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [property, setProperty] = React.useState(null);
    const [units, setUnits] = React.useState([])
    const [propertyId, setPropertyId] = React.useState(string || null);
    const [unitId, setUnitId] = React.useState(string);


    const token = localStorage.getItem('token');

    const getUnitByPropertyId = React.useCallback(async (currentPropertyId) => {

        if (currentPropertyId) {
            const token = localStorage.getItem('token');
            setLoading(true);
            setErrorText(null);
            const response = await axios.get(`${BURL}/admin/properties/${currentPropertyId}/units`, {
                params: {
                    withRooms: false,
                    withProperty: true
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLoading(false);
            if (response?.status) {
                const res = await response.data.data;

                const roomUnit = await res.find((unit) => unit.id === +currentUnitId);
                setRoomUnit(roomUnit)
                setProperty(res[0].property);
                setUnits(res);
            } else {
                // setErrorText(response.message ?? 'Request Error');
            }
        }
    }, [currentUnitId]);

    // React.useEffect(() => {
    //     getAllProperties();
    // }, []);

    React.useEffect(() => {
        if (currentPropertyId) {
            getUnitByPropertyId(currentPropertyId);
        }
    }, [currentPropertyId, currentUnitId, getUnitByPropertyId]);

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        setOpen(false);
        setDone(false);
        window.location.reload();
    };

    // when the dialog close
    React.useEffect(() => {
        if (!open && done) {
            setOpen(false);
            setDone(false);
            setActiveStep(0);
        }
        // refetch rooms if the method is been passed down to this component
        getRoomsByUnit && getRoomsByUnit();
        // eslint-disable-next-line
    }, [open, done])

    React.useEffect(() => {
        // if (propertyId) {
        getUnitByPropertyId();
        // }
    }, [currentPropertyId]);


    const addRooms = async () => {
        setLoading(true);
        setErrorText(null);
        values.propertyId = currentPropertyId;
        values.unitId = currentUnitId;
        const response = await POST(`admin/properties/units/rooms/new`, values);
        setLoading(false);
        if (response) {
            nextStep();
            values = '';
        } else {
            setErrorText(response.message);
        }
    }

    const loader = <SmallLoader />
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        <div className="max-w-md p-5 text-center">
                            Room setup has been completed. Click proceed to view rooms or click out to close this dialog.
                        </div>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Proceed</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div sx={{ mt: 2, mb: 1 }}>
                        {
                            activeStep === 0 &&
                            <div className="w-[20rem] lg:w-[30rem]">
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'> Room Details</h1>
                                    <p className="text-[0.8em] mt-2">Enter the room information.</p>
                                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                        <label htmlFor="" className=''>Property</label>
                                        <input type="text" disabled className='disabled:text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#0000008f]' name='property' defaultValue={property?.name} placeholder="Room BA" />
                                    </div>

                                    <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                        <label htmlFor="" className=''>Unit</label>
                                        <input type="text" disabled className='disabled:text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#0000008f]' name='unit' defaultValue={roomUnit?.name} placeholder="Room Unit" />
                                    </div>

                                    <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                        <label htmlFor="" className=''>Room Tag</label>
                                        <input type="text" className='text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#0000008f]' name='tag' value={values.tag} onChange={handleChange} placeholder="Room BA" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={handleNext} disabled={loading} className='flex gap-1 items-center'>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}

                            {loading && loader}
                        </Button>
                    </Box>
                </React.Fragment>
            )
            }
        </Box >
    );
}
