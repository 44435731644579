import React, { useState } from 'react'
import TransitionalModal from "../components/transitional";
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import { BURL } from '../../../services/base';
// Image
import SmallLoader from '../../widgets/SmallLoader';
import useAPI from '../../../hooks/useAPI';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Snackbar } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';
import TenantHorizontalLinearStepper from '../components/tenantLinearStepper';
import axios from 'axios';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Tenants = () => {
    const [loading, setLoading] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [success, setSuccess] = useState(null);
    const [properties, setProperties] = React.useState([]);
    const [tenants, setTenants] = React.useState([]);
    const [tenantsUnit, setTenantsUnit] = React.useState([]);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [tenantRoomId, setTenantRoomId] = React.useState(false);

    const { unitId, id } = useParams();

    const { deleteRequest } = useAPI();
    const [state, setState] = React.useState({
        opened: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opened } = state;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Formats as yyyy-mm-dd
    };

    const getTenantsByUnitRoom = React.useCallback(async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        setErrorText(null);
        const response = await axios.get(`${BURL}admin/tenants`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        setLoading(false);

        if (response?.status) {
            const res = await response.data.data
            console.log('tenants', res);
            setTenants(res);
            // setRooms(res);
            // setUnitName(res[0].unit.name);
        } else {
            // setErrorText(response.message ?? 'Request Error');
        }
    }, [])

    React.useEffect(() => {
        getTenantsByUnitRoom();
    }, [getTenantsByUnitRoom])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleConfirmation = (roomId) => {
        setConfirmationOpen(true);
        setTenantRoomId(roomId)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSnackbarClose = (
        event,
        reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });
    };


    const handleClickOpen = () => {
        setShowModal(true);
    };

    const removeTenant = (newState) => async (propertyId) => {
        const token = localStorage.getItem('token');
        setConfirmLoading(true);
        setErrorText(null);

        try {
            const url = `${BURL}admin/tenant-room`;
            const body = { propertyId, tenantRoomId };
            const response = await deleteRequest(url, body, token);
            setSuccess(response.message);
            if (response?.status) {
                setSuccess(response.message)
                getTenantsByUnitRoom();
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            setErrorText(err);
        } finally {
            setLoading(false);
            setConfirmationOpen(false);
        }
    };

    const loader = <SmallLoader />

    return (
        <>
            <div className='w-[100%] bg-white py-8 overflow-x-hidden'>
                {/* Property section */}
                <div className="px-[1.22rem] md:px-4 xl:px-10 bg-white overflow-x-hidden  md:w-[99%] lg:w-[82%] xl:w-[96%] 2xl:w-full">
                    <div className='flex justify-between items-center'>
                        <p className='mb-[3rem] xl:mb-[5rem] font-semibold text-[16px] text-gray-400'>Tenants</p>
                        <button className='px-4 py-2 hover:text-[#0FA958] hover:font-semibold flex items-center gap-1' onClick={handleClickOpen}> <AddHomeWorkIcon className='text-[#6C6C6C]' /> <p className='flex font-light text-[14px] md:text-[16px] items-center py-auto mt-2'>Add Tenant</p>
                        </button>
                    </div>
                    {loading && <SmallLoader />}
                    {(errorText && !loading) && errorText}
                    {!errorText && !loading && !tenants.length && <div className='mt-24 flex gap-5 lg:mx-0 justify-center text-center'>{(!errorText && !loading && !tenants.length)
                        && 'There is currently no tenant. Click CREATE TENANT to add a tenant to a property.'}
                    </div>}
                    {errorText && <Button onClick={getTenantsByUnitRoom}>Try again</Button>}
                    {!loading && tenants.length != 0 &&
                        <div className='overflow-x-auto my-table w-[100%]'>
                            <table className='table-auto w-full text-[#6C6C6C]'>
                                <thead>
                                    <th>Room No</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Check In</th>
                                    <th>Check Out</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {tenants.map((tenant, index) =>
                                        <tr className='mb-[30rem] mt-20 border-b-8 border-white'>
                                            <td className='capitalize font-bold text-[#0FA958] text-[16px]'>{tenant?.tenancyHistory[0]?.room?.tag}</td>
                                            <td className='capitalize text-[16px]'>{tenant?.user.firstname}</td>
                                            <td className='capitalize text-[16px]'>{tenant?.user.lastname}</td>
                                            {/* <td className='capitalize'>{tenant.user.firstname} {tenant.user.lastname}</td> */}
                                            <td className='text-[16px]'>{tenant.user.email}</td>
                                            <td className='text-[16px]'>{formatDate(tenant?.tenancyHistory[0]?.checkIn)}</td>
                                            <td className='text-[16px]'>{formatDate(tenant?.tenancyHistory[0]?.checkOut)}</td>
                                            <td className='flex gap-4 items-center'>
                                                <RemoveRedEyeOutlined />
                                                < DeleteIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} onClick={() => handleConfirmation(tenant?.tenantRoom?.tenantRoomId)} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowModal(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TenantHorizontalLinearStepper open={showModal} setOpen={setShowModal} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            </div>
        </>
    )
}

export default Tenants
