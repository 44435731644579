import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFormik } from 'formik';
import UnitAccordion from '../components/unitAccordion';
import useAPI from '../../../hooks/useAPI';
import SmallLoader from '../../widgets/SmallLoader'
import { BURL, states } from '../../../services/base'
import { useNavigate } from 'react-router';
import { assignAdminInitialValues, pptInitialValues, pptTypes } from '../../../services/utils';
import axios from 'axios';
import { textTransform } from '@mui/system';
import { capitalize } from '@mui/material';


export default function HorizontalLinearStepper({ getProperties, open, setOpen }) {
    const steps = ['Property Details', 'Unit Setup'];

    // Formik form
    const { values, handleChange, resetForm } = useFormik({ initialValues: pptInitialValues })
    // End of formik

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const { POST } = useAPI()
    const [loading, setLoading] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [property, setProperty] = React.useState(null)

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const nextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }


    const handleNext = () => {
        // create property before going to units setup
        if (activeStep + 1 === 1) {
            createProperty();
            // setup units before showing `setup complete`
        } else if (activeStep + 1 === 2) {
            saveUnit();
        } else {
            nextStep()
        }
        // if(ac)
    };

    const createProperty = async () => {
        setLoading(true);
        setErrorText(null);
        const response = property
            ? await POST(`admin/properties/update`, {
                ...property, ...values,
                propertyId: property.id
            })
            : await POST(`admin/properties/new`, values);
        setLoading(false);

        if (response?.status) {
            !property && setProperty(response.data);
            nextStep()
        } else {
            setErrorText(response.message);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        setOpen(false);
        setDone(false);
        navigate('/dashboard/properties');
    };

    // States in nigeria
    const statesItemList = states.map(stateItem => {
        return (
            <MenuItem value={stateItem}>{stateItem}</MenuItem>
        )
    })
    // End of states in  Nigeria

    // Property type
    const typeList = pptTypes.map(propertyItem => {
        return (
            <MenuItem value={propertyItem}>{propertyItem}</MenuItem>

        )
    })
    // End of property type  

    // handling multiple units
    const [units, setUnits] = React.useState([])

    React.useEffect(() => {
        if (activeStep === 1 && !units.length) addUnit();
        // eslint-disable-next-line
    }, [activeStep, units.length])

    const addUnit = () => {
        setUnits(e => [...e, {
            id: e.length + 1,
            name: 'New Unit ' + (e.length + 1),
            noOfRooms: 1, saved: false,
            rooms: [{ tag: 'Room 1' }],
        }])
    }

    const saveUnit = async () => {
        // get unsaved units
        const filtered = units.filter(each => !each.saved)
        // loop through each unsaved units and save
        try {
            for (let i = 0; i < filtered.length; i++) {
                const eachUnit = filtered[i];
                updateUnit(eachUnit, { loading: true, error: false })
                const response = await POST('admin/properties/units/new', { ...eachUnit, propertyId: property.id });
                if (response?.status) {
                    updateUnit(eachUnit, { error: false, saved: true, loading: false })
                } else {
                    updateUnit(eachUnit, { error: response?.message, loading: false })
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const updateUnit = (unit, update) => {
        setUnits(e => e.map((eachUnit) => eachUnit.id === unit.id ? ({ ...eachUnit, ...update }) : eachUnit));
    }

    // when the dialog close
    React.useEffect(() => {
        if (!open && done) {
            setOpen(false);
            setDone(false);
            setActiveStep(0);
        }
        // refetch properties if the method is been passed down to this component
        getProperties && getProperties();
        // eslint-disable-next-line
    }, [open, done])


    // check if setup is completed
    React.useEffect(() => {
        // get unsaved units
        const unsavedUnits = units.filter(each => !each.saved)
        // unit setup step is skipped or (we have units, no unsaved unit and property has been set)
        if ((!unsavedUnits.length && property && units.length) || isStepSkipped(1)) {
            resetForm();
            setDone(true)
            setUnits([]);
            setActiveStep(2);
            setProperty(null);
            setSkipped(new Set())
        }
        // eslint-disable-next-line
    }, [units, property, activeStep])

    const loader = <SmallLoader />
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //     labelProps.optional = (
                    //         <Typography variant="caption">Optional</Typography>
                    //     );
                    // }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        <div className="max-w-md p-5 text-center">
                            Property setup complete. Click proceed to view your properties or click out to close this dialog.
                        </div>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Proceed</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div sx={{ mt: 2, mb: 1 }}>
                        {
                            activeStep === 0 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'> Property Details</h1>
                                    <p className="text-[0.8em] mt-2">Enter your address information, then navigate to the property details section.</p>
                                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                </div>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className="w-100 gap-3 grid md:grid-cols-[58%,40%]">
                                        <TextField id="standard-basic" label="Property Name" variant="outlined" name='name' value={values.name} onChange={handleChange} />

                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth >
                                                <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='type'
                                                    value={values.type}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }}
                                                >
                                                    {typeList}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>

                                    <div className="w-100 gap-3 gap-y-3 grid md:grid-cols-3 mt-5">
                                        <TextField id="standard-basic" label="Street" variant="outlined" name='street' value={values.street} onChange={handleChange} />

                                        <TextField id="standard-basic" label="City" variant="outlined" onChange={handleChange} value={values.city} name='city' />
                                        <TextField id="standard-basic" label="Zip" variant="outlined" type='number' value={values.zip} name='zip' onChange={handleChange} />

                                    </div>


                                    <div className="w-100 gap-3 grid md:grid-cols-[38%,60%] mt-5">
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth sx={{ color: '#6C6C6C' }}>
                                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={values.state}
                                                    name='state'
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }}
                                                >
                                                    {statesItemList}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <TextField id="standard-basic" label="Country" type='text' variant="outlined" name='country' value={values.country} onChange={handleChange} />
                                    </div>

                                    <div className="mt-5 grid">
                                        <TextField id="standard-basic" label="Street Address" type='text' variant="outlined" name='address' value={values.address} onChange={handleChange} />
                                    </div>

                                    {/* Comment */}
                                    <TextField id="standard-basic" label="Description" variant="standard" value={values.description} name='description' placeholder='More details about my property' onChange={handleChange} sx={{ marginTop: '12px', marginBottom: '1em' }} />
                                </Box>
                            </div>
                        }
                        {
                            activeStep === 1 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'>Unit Setup</h1>
                                    <p className="text-[0.8em] mt-2">Enter the number of rooms for each of the following units. Click ADD UNIT to add more units to this property. <br /> NOTE: No unit will be created if this step is skipped</p>
                                </div>
                                <UnitAccordion setUnits={setUnits} units={units} addUnit={addUnit} />
                            </div>
                        }

                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={handleNext} disabled={loading} className='flex gap-1 items-center'>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}

                            {loading && loader}
                        </Button>
                    </Box>
                </React.Fragment>
            )
            }
        </Box >
    );
}

export function HorizontalLinearAssignAdminStepper({ getProperties, open, setOpen, currentPropertyId }) {
    const steps = ['Find Admin', 'Admin Details', 'Assign Admin'];
    // Formik form
    const { values, handleChange, resetForm } = useFormik({ initialValues: assignAdminInitialValues })
    // End of formik
    const { POST } = useAPI()
    const [loading, setLoading] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [isAdminDetails, setIsAdminDetails] = React.useState(false);
    const [adminId, setAdminId] = React.useState('');
    const [roleId, setRoleId] = React.useState('');
    const [success, setSuccess] = React.useState(null);
    const [roles, setRoles] = React.useState([]);
    const [errorText, setErrorText] = React.useState(null);
    const [adminDetails, setAdminDetails] = React.useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        userId: 0,
        roleId: '',
        propertyId: 0
    });
    const token = localStorage.getItem('token');
    const [adminEmail, setAdminEmail] = React.useState('');
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [assignPropertyPayload, setAssignPropertyPayload] = React.useState({
        userId: '',
        roleId: '',
        propertyId: ''
    });
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleadminEmailChange = (e) => {
        setAdminEmail(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdminDetails({
            ...adminDetails,
            [name]: value
        });
    };

    const nextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    const handleNext = () => {
        setLoading(true);
        if (activeStep + 1 === 1) {
            findUserWithEmail();
        } else if (activeStep + 1 === 3) {
            assignProperty();
        } else {
            nextStep()
        }
    };

    const handleRoleChange = (e) => {
        setRoleId(e.target.value);
        setAssignPropertyPayload({
            userId: adminId,
            roleId: e.target.value,
            propertyId: currentPropertyId
        });
        if (!isAdminDetails) {
            adminDetails.roleId = e.target.value;
            adminDetails.propertyId = Number(currentPropertyId);
        }
    };

    const findUserWithEmail = async () => {
        setIsAdminDetails(false);
        setErrorText('');
        try {
            const response = await axios.get(`${BURL}/app/user`, {
                params: {
                    email: adminEmail
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.status) {
                response.data.data ? setIsAdminDetails(true) : setIsAdminDetails(false);
                setAdminId(response.data.data.userId);
                setAdminDetails(response.data.data);
                nextStep();
            } else {
                const errorMessage = typeof response.message === 'string'
                    ? response.message
                    : JSON.stringify(response.message);
                setErrorText(errorMessage);
                setIsAdminDetails(false);
            }
        } catch (err) {
            if (err?.response.data.message == 'Account does not exist') {
                setAdminDetails({
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: ''
                });
                nextStep();
            }else {
                setErrorText(err?.response.data.message || 'An unexpected error occurred.');
                setIsAdminDetails(false);
            }
        } finally {
            setLoading(false);
        }
    }

    const getAllRoles = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BURL}/app/roles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const res = response.data.data;
            setRoles(res);
        } catch (err) {
            setErrorText(err);
        } finally {
            setLoading(false);
        }
    };
    
    const assignProperty = async () => {
        setLoading(true);
        setErrorText(null);
        try {
            // nextStep();
          const response = isAdminDetails ? await POST(`admin/properties/assign`, assignPropertyPayload) : await POST(`admin/properties/assign`, adminDetails);
            if (response?.status) {
                setSuccess(response.message);
                nextStep();
            } else {
                const errorMessage = typeof response.message === 'string'
                    ? response.message
                    : JSON.stringify(response.message);
                setErrorText(errorMessage);
            }
        } catch (err) {
            setErrorText(err?.message || 'An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setOpen(false);
        setDone(false);
        setAdminDetails('');
        window.location.reload(`/dashboard/properties/${currentPropertyId}`);
    };

    // handling multiple units
    const [units, setUnits] = React.useState([])

    React.useEffect(() => {
        getAllRoles();
        // eslint-disable-next-line
    }, [activeStep, units.length])

    // when the dialog close
    React.useEffect(() => {
        if (!open && done) {
            setOpen(false);
            setDone(false);
            setActiveStep(0);
        }
        // refetch properties if the method is been passed down to this component
        getProperties && getProperties();
        // eslint-disable-next-line
    }, [open, done])

    const loader = <SmallLoader />
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        <div className="max-w-md p-5 text-center">
                            Assigned admin property. Click proceed to view your property details or click out to close this dialog.
                        </div>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Proceed</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div sx={{ mt: 2, mb: 1 }}>
                        {
                            activeStep === 0 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'>Find Admin</h1>
                                    <p className="text-[0.8em] mt-2">Enter the admin email address, to fetch the admin details.</p>
                                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                </div>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className='w-full'>
                                        <TextField fullWidth name="adminEmail" label="Email" type='email' value={adminEmail} onChange={handleadminEmailChange} variant="outlined" />
                                    </div>
                                </Box>
                            </div>
                        }
                        {
                            activeStep === 1 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'>Admin Details</h1>
                                    {isAdminDetails ? <p className="text-[0.8em] mt-2">Below is the details of the admin. <br /> NOTE: This is displayed because the searched email address is registered. </p> :
                                        <p className="text-[0.8em] mt-2">User does not exist. <br /> Fill in the neccessary information to create admin </p>
                                    }
                                </div>
                                <div className='flex flex-col gap-6'>
                                    <div className="w-100 gap-3 grid grid-cols-[58%,40%] capitalize">
                                        <TextField sx={{
                                            "& .MuiInputBase-input": {
                                                textTransform: "capitalize",
                                            },
                                        }} fullWidth disabled={isAdminDetails} name="firstname" label="First Name" type='text' value={adminDetails.firstname} onChange={handleInputChange} variant="outlined" />
                                        <TextField sx={{
                                            "& .MuiInputBase-input": {
                                                textTransform: "capitalize",
                                            },
                                        }} fullWidth disabled={isAdminDetails} name="lastname" label="Last Name" type='text' value={adminDetails?.lastname} onChange={handleInputChange} variant="outlined" />
                                    </div>
                                    <TextField sx={{
                                        "& .MuiInputBase-input": {
                                            textTransform: "lowercase",
                                        },
                                    }} fullWidth disabled={isAdminDetails} name="email" label="Email" type='email' value={adminDetails?.email} onChange={handleInputChange} variant="outlined" />
                                    <div className="w-100 gap-3 grid">
                                        {/* <TextField sx={{
                                            "& .MuiInputBase-input": {
                                                textTransform: "capitalize",
                                            },
                                        }} fullWidth disabled={isAdminDetails} name="address" label="Address" type='text' value={adminDetails?.address} onChange={handleInputChange} variant="outlined" /> */}
                                        <TextField sx={{
                                            "& .MuiInputBase-input": {
                                                textTransform: "capitalize",
                                            },
                                        }} fullWidth disabled={isAdminDetails} name="phone" label="Phone Number" type='text' value={adminDetails?.phone} onChange={handleInputChange} variant="outlined" />
                                    </div>

                                </div>
                                {/* <UnitAccordion setUnits={setUnits} units={units} addUnit={addUnit} /> */}
                            </div>
                        }
                              {
                            activeStep === 2 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'>Assign Admin</h1>
                                    <p className="text-[0.8em] mt-2">Select the role of the admin.</p>
                                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                </div>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className='w-full'>
                                    <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth
                                name='roleId'
                                value={roleId}
                                onChange={handleRoleChange}
                                MenuProps={{
                                    style: {
                                        maxHeight: 300,
                                        width: 100
                                    },
                                }}
                            >
                                {roles.map((role) => (
                                    <MenuItem value={role.id}>{role.name}</MenuItem>
                                ))}
                            </Select>
                                    </div>
                                </Box>
                            </div>
                        }

                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )} */}

                        <Button onClick={handleNext} disabled={loading} className='flex gap-1 items-center'>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}

                            {loading && loader}
                        </Button>
                    </Box>
                </React.Fragment>
            )
            }
        </Box >
    );
}