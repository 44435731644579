import { Checkbox, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import irrImg from '../../assets/auth-bg.svg'
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { useFormik } from 'formik'
import { signInSchema } from '../../schemas/validation.schema'
import useAPI from '../../hooks/useAPI'

export default function Login() {

  const navigate = useNavigate();
  // Page title
  useEffect(() => {
    document.title = "Apartmential | Login"
  })

  const { POST } = useAPI()
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const handleLogin = async (values) => {
    setLoading(true);
    setErrorText(null);
    const response = await POST('admin-login', values);
    setLoading(false);
    if (response?.status) {
      localStorage.setItem("token", response.token);
      navigate('/dashboard/home');
    } else {
      setErrorText(response.message);
    }
  }

  // Beginning of formik form
  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: signInSchema,
    onSubmit: handleLogin
  });
  // End of formik form

  return (
    <div className='flex h-screen p-2 bg-house-pattern bg-tr-white justify-center bg-no-repeat bg-center bg-cover bg-blend-color-burn items-center'>
      <img draggable="false" className='fixed top-auto' width={615} src={irrImg} alt="bg" />
      <div className='z-10 px-10 pt-10 pb-20 m-auto w-[450px] shadow-xl bg-white text-center'>

        <h3 className='text-3xl font-bold text-austel-green'>Login</h3>

        <span className='text-austel-green text-sm block mb-3 font-semibold'>Get started on Apartmential</span>
        <form onSubmit={handleSubmit}>
          {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}

          <CustomTextField label="Email" name="email" value={values.email} onChange={handleChange} />
          {touched.email && errors.email && <div className='text-red-600 text-xs text-left'>{errors.email}</div>}
          <FormControl sx={{ width: '100%', marginTop: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password" size='small'>Password</InputLabel>
            <Input
              type="password"
              name='password'
              value={values.password}
              onChange={handleChange}
            />
          </FormControl>
          {touched.password && errors.password && <div className='text-red-600 text-xs text-left'>{errors.password}</div>}

          <div className='flex text-xs text-gray-700 text-left mt-5 mb-8 items-center'>
            <Checkbox style={{ padding: '0' }} size='small' />
            <span className='text-[14px] text-gray-400 ml-1'>Remember me</span>
          </div>

          <button className='text-white bg-austel-green py-3 px-20 my-2 block mx-auto text-sm' type='submit'>{loading ? 'Signing in...' : "Sign In"}</button>

        </form>
        <div className='flex flex-col gap-4'>
          <span className="text-sm mt-2 text-gray-400 underline cursor-pointer"><Link to="/reset-password">Forget Password?</Link></span>
          <span className="text-sm mt-2 text-gray-400">Don't have an account? <Link to="/Signup" className="text-austel-green">Register</Link></span>
        </div>

      </div>
    </div>
  )
}

const CustomTextField = (props) => {
  const styles = {
    label: {
      fontSize: 13
    },
    inputP: {
      fontSize: 13,
    }
  }
  return <>
    <TextField
      required
      variant='standard'
      size='small'
      fullWidth
      InputLabelProps={{ style: styles.label }}
      InputProps={{
        style: styles.inputP
      }}
      {...props}
    />
  </>
}
